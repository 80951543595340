module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"447373727622981","trackPageViews":{"includePaths":["/undergraduates/make-the-most-of-summer/","/apply","/undergraduates/undergraduate-costs/","/undergraduates/undergraduate-course-finder/","/undergraduates/undergrad-student-registration/visiting-undergraduate-students/","/undergraduates/undergraduate-housing/","/undergraduates/undergrad-faq/"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-KC8J6L04RE"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/University_of_Chicago_shield.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e94900cb3908e8e3ee91774d3a8be3fb"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
